import { useTranslation } from "react-i18next";
import axios from "axios";
import { isObject } from "lodash";

type Callback = (error: Error) => string;

export function useExtraErrorMessage(): Callback {
    const { t } = useTranslation();

    return (error) => {
        let result = '';
        if (axios.isAxiosError(error)) {
            if (error.response) {
                if (error.response.status === 400 || error.response.status === 404) {
                    if (isObject(error.response.data)) {
                        for (const [key, value] of Object.entries(error.response.data)) {
                            const keyText = t(`shared.${key}`);
                            if (Array.isArray(value)) {
                                result += `${keyText} : ${error}\n`;
                            } else {
                                result += `${keyText} : ${value}`;
                            }
                        }
                    } else {
                        result = error.message;
                    }
                } else if (error.response.status === 401 || error.response.status === 403) {
                    result = t('errors.permissions');
                } else if (error.response.status === 409) {
                    result = t('errors.conflict') + ': ' + error.response.data?.error;
                } else if (error.response.status === 500) {
                    result = t('errors.servers');
                }
            }
        } else {
            result = error.message;
        }

        return result;
    };
}
