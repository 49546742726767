import moment from "moment";
import SortByKey from "../Components/Common/SortByKey";
import { addProviderDates } from "../Components/CartMaterial/utils/addProviderDates";

const initial_state = {
    calendar_price: null,
    //General Data
    owner: null,
    client_type: null,
    full_list: null,
    price_list: null,
    page_list: null,
    count: 0,
    active_page: 1,
    grid_type: 4,
    destination: null,
    bounds: null,
    current_groups: null,
    room_number: 0,
    traveler_number: 0,
    loading: false,
    cart: [],
    sort_cart: [],
    hovered: null,
    seen: [],
    auto_reload: true,
    active_filters: 0,
    run_small: false,
    run_big: false,
    providers_running_count: 0,
    static_running_count: 0,
    run_dynamic: false,
    only_static: false,
    card_unmount: false,
    refresh: null,
    //Parameters
    show_parameters: false,
    start_date: null,
    end_date: null,
    duration_days: 0,
    //Search
    search: "",
    //Sorts
    show_sorts: false,
    sort: null,
    //Filters
    show_filters: false,
    recommended: (JSON.parse(localStorage.getItem("config")) !== null && JSON.parse(localStorage.getItem("config")).quotation_code === "amerigo"),
    selected_providers: [],
    meal_plan: null,
    cancellable: false,
    resort_fees: false,
    star_1: false,
    star_2: false,
    star_3: false,
    star_4: false,
    star_5: false,
    check_in: false,
    bar: false,
    restaurant: false,
    kitchen: false,
    change: false,
    receptionist: false,
    elevator: false,
    parking: false,
    swimming_pool: false,
    room_service: false,
    television: false,
    safe: false,
    private_beach: false,
    wifi: false,
    air_conditioning: false,
    mini_bar: false,
    //card
    additional_products: [],
    open_cancellation_info: false,
    cancellation_info: [],
    cancellation_text: null,
    cancellation_currency: null,
    additional_info: null,
    check_in_info: null,
    taxes_info: false,
    replace_id: null,
    wait: false,

    late_arrival: false,
    late_departure: false,
    choose_late_departure: false,
    choose_late_arrival: false,
};

const filterResults = (full_list, new_state) => {
    let filtered_results = [];
    full_list.map((hotel) => {
        if (!!new_state.bounds && new_state.bounds.north > parseFloat(hotel.latitude) && parseFloat(hotel.latitude) > new_state.bounds.south && new_state.bounds.east > parseFloat(hotel.longitude) && parseFloat(hotel.longitude) > new_state.bounds.west) {
            hotel.price = 0;
            hotel.price_currency = null;
            hotel.breakfast_price = 0;
            hotel.breakfast_currency = null;
            hotel.half_price = 0;
            hotel.half_currency = null;
            hotel.full_price = 0;
            hotel.full_currency = null;
            hotel.c_breakfast_price = 0;
            hotel.c_breakfast_currency = null;
            hotel.c_half_price = 0;
            hotel.c_half_currency = null;
            hotel.c_full_price = 0;
            hotel.c_full_currency = null;
            hotel.cancellable_price = 0;
            hotel.cancellable_currency = null;
            hotel.all_inclusive_price = 0;
            hotel.all_inclusive_currency = null;
            hotel.c_all_inclusive_price = 0;
            hotel.c_all_inclusive_currency = null;
            let default_rule_catalog_price = new_state.selected_providers.length === 0;
            hotel.providers.map((provider) => {
                if (new_state.selected_providers.length > 0 && new_state.selected_providers.includes(provider.provider.provider.id)) {
                    if (provider.prices.details !== undefined && provider.prices.details !== null && provider.prices.details.catalog_price) {
                        default_rule_catalog_price = true;
                    }
                }
                if ((new_state.selected_providers.length === 0 || new_state.selected_providers.includes(provider.provider.provider.id)) && (!new_state.resort_fees || provider.prices.details === undefined || provider.prices.details === null || provider.prices.details.resort_fees_included || provider.prices.details.resort_fees === 0)) {
                    const price = getPriceByOwner(hotel.price, hotel.price_currency, hotel.price_resort_fees, provider.prices.prices, provider.prices.details, new_state.owner);
                    hotel.price = price.return_price;
                    hotel.price_currency = price.return_currency;
                    hotel.price_resort_fees = price.return_resort_fees;
                    const breakfast = getPriceByOwner(hotel.breakfast_price, hotel.breakfast_currency, hotel.breakfast_resort_fees, provider.prices.breakfast_price, provider.prices.details, new_state.owner);
                    hotel.breakfast_price = breakfast.return_price;
                    hotel.breakfast_currency = breakfast.return_currency;
                    hotel.breakfast_resort_fees = breakfast.return_resort_fees;
                    const half = getPriceByOwner(hotel.half_price, hotel.half_currency, hotel.half_resort_fees, provider.prices.half_price, provider.prices.details, new_state.owner);
                    hotel.half_price = half.return_price;
                    hotel.half_currency = half.return_currency;
                    hotel.half_resort_fees = half.return_resort_fees;
                    const full = getPriceByOwner(hotel.full_price, hotel.full_currency, hotel.full_resort_fees, provider.prices.full_price, provider.prices.details, new_state.owner);
                    hotel.full_price = full.return_price;
                    hotel.full_currency = full.return_currency;
                    hotel.full_resort_fees = full.return_resort_fees;
                    const c_breakfast = getPriceByOwner(hotel.c_breakfast_price, hotel.c_breakfast_currency, hotel.c_breakfast_resort_fees, provider.prices.c_breakfast_price, provider.prices.details, new_state.owner);
                    hotel.c_breakfast_price = c_breakfast.return_price;
                    hotel.c_breakfast_currency = c_breakfast.return_currency;
                    hotel.c_breakfast_resort_fees = c_breakfast.return_resort_fees;
                    const c_half = getPriceByOwner(hotel.c_half_price, hotel.c_half_currency, hotel.c_half_resort_fees, provider.prices.c_half_price, provider.prices.details, new_state.owner);
                    hotel.c_half_price = c_half.return_price;
                    hotel.c_half_currency = c_half.return_currency;
                    hotel.c_half_resort_fees = c_half.return_resort_fees;
                    const c_full = getPriceByOwner(hotel.c_full_price, hotel.c_full_currency, hotel.c_full_resort_fees, provider.prices.c_full_price, provider.prices.details, new_state.owner);
                    hotel.c_full_price = c_full.return_price;
                    hotel.c_full_currency = c_full.return_currency;
                    hotel.c_full_resort_fees = c_full.return_resort_fees;
                    const all_inclusive = getPriceByOwner(hotel.all_inclusive_price, hotel.all_inclusive_currency, hotel.all_inclusive_resort_fees, provider.prices.all_inclusive_price, provider.prices.details, new_state.owner);
                    hotel.all_inclusive_price = all_inclusive.return_price;
                    hotel.all_inclusive_currency = all_inclusive.return_currency;
                    hotel.all_inclusive_resort_fees = all_inclusive.return_resort_fees;
                    const c_all_inclusive = getPriceByOwner(hotel.c_all_inclusive_price, hotel.c_all_inclusive_currency, hotel.c_all_inclusive_resort_fees, provider.prices.c_all_inclusive_price, provider.prices.details, new_state.owner);
                    hotel.c_all_inclusive_price = c_all_inclusive.return_price;
                    hotel.c_all_inclusive_currency = c_all_inclusive.return_currency;
                    hotel.c_all_inclusive_resort_fees = c_all_inclusive.return_resort_fees;
                    const cancelable = getPriceByOwner(hotel.cancellable_price, hotel.cancellable_currency, hotel.cancellable_resort_fees, provider.prices.cancellable_price, provider.prices.details, new_state.owner);
                    hotel.cancellable_price = cancelable.return_price;
                    hotel.cancellable_currency = cancelable.return_currency;
                    hotel.cancellable_resort_fees = cancelable.return_resort_fees;
                }
            });
            console.log('hotel :' , hotel);
            if (
                (
                    (default_rule_catalog_price && hotel.catalog_price) || hotel.price > 0
                ) &&
                (
                    new_state.search === "" || (hotel.name !== null && hotel.name.match(new RegExp(new_state.search, 'i')))
                ) &&
                (
                    !new_state.recommended || (new_state.recommended && hotel.favorite)
                ) &&
                (
                    !new_state.resort_fees ||
                    (new_state.resort_fees && ((new_state.meal_plan === null && !new_state.cancellable && hotel.price_resort_fees === null) ||
                    (new_state.meal_plan === null && new_state.cancellable && hotel.cancellable_resort_fees === null) ||
                    (new_state.meal_plan === 0 && !new_state.cancellable && hotel.breakfast_resort_fees === null) ||
                    (new_state.meal_plan === 1 && !new_state.cancellable && hotel.half_resort_fees === null) ||
                    (new_state.meal_plan === 2 && !new_state.cancellable && hotel.full_resort_fees === null) ||
                    (new_state.meal_plan === 0 && new_state.cancellable && hotel.c_breakfast_resort_fees === null) ||
                    (new_state.meal_plan === 1 && new_state.cancellable && hotel.c_half_resort_fees === null) ||
                    (new_state.meal_plan === 2 && new_state.cancellable && hotel.c_full_resort_fees === null)))
                ) &&
                (
                    (!new_state.star_1 && !new_state.star_2 && !new_state.star_3 && !new_state.star_4 && !new_state.star_5) ||
                    ((new_state.star_1 && hotel.stars === 1) || (new_state.star_2 && hotel.stars === 2) || (new_state.star_3 && hotel.stars === 3) || (new_state.star_4 && hotel.stars === 4) || (new_state.star_5 && hotel.stars === 5))
                ) &&
                (
                    !new_state.check_in || (new_state.check_in && hotel.checkin)) && (!new_state.bar || (new_state.bar && hotel.bar)
                ) &&
                (!new_state.restaurant || (new_state.restaurant && hotel.restaurant)) &&
                (!new_state.kitchen || (new_state.kitchen && hotel.kitchen)) &&
                (!new_state.change || (new_state.change && hotel.change)) &&
                (!new_state.receptionist || (new_state.receptionist && hotel.receptionist)) &&
                (!new_state.elevator || (new_state.elevator && hotel.elevator)) &&
                (!new_state.parking || (new_state.parking && hotel.parking)) &&
                (!new_state.swimming_pool || (new_state.swimming_pool && hotel.pool)) &&
                (!new_state.room_service || (new_state.room_service && hotel.room_service)) &&
                (!new_state.television || (new_state.television && hotel.tv)) &&
                (!new_state.safe || (new_state.safe && hotel.safe)) &&
                (!new_state.private_beach || (new_state.private_beach && hotel.beach)) &&
                (!new_state.wifi || (new_state.wifi && hotel.internet)) &&
                (!new_state.air_conditioning || (new_state.room_service && hotel.clim)) &&
                (!new_state.mini_bar || (new_state.mini_bar && hotel.minibar)) &&
                (
                    (new_state.meal_plan === null && !new_state.cancellable) ||
                    (new_state.meal_plan === null && new_state.cancellable && hotel.cancellable_price !== 0) ||
                    (new_state.meal_plan === 0 && !new_state.cancellable && hotel.breakfast_price !== 0) ||
                    (new_state.meal_plan === 1 && !new_state.cancellable && hotel.half_price !== 0) ||
                    (new_state.meal_plan === 2 && !new_state.cancellable && hotel.full_price !== 0) ||
                    (
                        new_state.meal_plan === 3 &&
                        !new_state.cancellable &&
                        hotel.breakfast_price === 0 &&
                        hotel.half_price === 0 &&
                        hotel.all_inclusive_price === 0 &&
                        hotel.full_price === 0
                    ) ||
                    (new_state.meal_plan === 4 && !new_state.cancellable && hotel.all_inclusive_price !== 0) ||
                    (new_state.meal_plan === 0 && new_state.cancellable && hotel.c_breakfast_price !== 0) ||
                    (new_state.meal_plan === 1 && new_state.cancellable && hotel.c_half_price !== 0) ||
                    (new_state.meal_plan === 2 && new_state.cancellable && hotel.c_full_price !== 0) ||
                    (
                        new_state.meal_plan === 3 &&
                        new_state.cancellable &&
                        hotel.c_breakfast_price === 0 &&
                        hotel.c_half_price === 0 &&
                        hotel.c_all_inclusive_price === 0 &&
                        hotel.c_full_price === 0
                    ) ||
                    (new_state.meal_plan === 4 && new_state.cancellable && hotel.c_all_inclusive_price !== 0)
                )
            ) {
                filtered_results.push(hotel);
            }
        }
    });
    return filtered_results;
};

const sortResults = (list, state) => {
    if (list === null) {
        return list;
    }
    let sorted_results = [...list];
    switch (state.sort) {
        case 0:
            if (state.meal_plan === null && !state.cancellable) {
                sorted_results.sort((a, b) => {
                    return a.price - b.price;
                });
            } else if (state.meal_plan === null && state.cancellable) {
                sorted_results.sort((a, b) => {
                    return a.cancellable_price - b.cancellable_price;
                });
            } else if (state.meal_plan === 0 && !state.cancellable) {
                sorted_results.sort((a, b) => {
                    return a.breakfast_price - b.breakfast_price;
                });
            } else if (state.meal_plan === 1 && !state.cancellable) {
                sorted_results.sort((a, b) => {
                    return a.half_price - b.half_price;
                });
            } else if (state.meal_plan === 2 && !state.cancellable) {
                sorted_results.sort((a, b) => {
                    return a.full_price - b.full_price;
                });
            } else if (state.meal_plan === 0 && state.cancellable) {
                sorted_results.sort((a, b) => {
                    return a.c_breakfast_price - b.c_breakfast_price;
                });
            } else if (state.meal_plan === 1 && state.cancellable) {
                sorted_results.sort((a, b) => {
                    return a.c_half_price - b.c_half_price;
                });
            } else if (state.meal_plan === 2 && state.cancellable) {
                sorted_results.sort((a, b) => {
                    return a.c_full_price - b.c_full_price;
                });
            }
            break;
        case 1:
            if (state.meal_plan === null && !state.cancellable) {
                sorted_results.sort((a, b) => {
                    return b.price - a.price;
                });
            } else if (state.meal_plan === null && state.cancellable) {
                sorted_results.sort((a, b) => {
                    return b.cancellable_price - a.cancellable_price;
                });
            } else if (state.meal_plan === 0 && !state.cancellable) {
                sorted_results.sort((a, b) => {
                    return b.breakfast_price - a.breakfast_price;
                });
            } else if (state.meal_plan === 1 && !state.cancellable) {
                sorted_results.sort((a, b) => {
                    return b.half_price - a.half_price;
                });
            } else if (state.meal_plan === 2 && !state.cancellable) {
                sorted_results.sort((a, b) => {
                    return b.full_price - a.full_price;
                });
            } else if (state.meal_plan === 0 && state.cancellable) {
                sorted_results.sort((a, b) => {
                    return b.c_breakfast_price - a.c_breakfast_price;
                });
            } else if (state.meal_plan === 1 && state.cancellable) {
                sorted_results.sort((a, b) => {
                    return b.c_half_price - a.c_half_price;
                });
            } else if (state.meal_plan === 2 && state.cancellable) {
                sorted_results.sort((a, b) => {
                    return b.c_full_price - a.c_full_price;
                });
            }
            break;
        case 2:
            sorted_results.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            break;
        case 3:
            sorted_results.sort((a, b) => {
                return b.name.localeCompare(a.name);
            });
            break;
        case 4:
            sorted_results.sort((a, b) => {
                return a.stars - b.stars;
            });
            break;
        case 5:
            sorted_results.sort((a, b) => {
                return b.stars - a.stars;
            });
            break;
    }
    return sorted_results;
};

const getPageResults = (list, page) => {
    let page_list = [];
    for (let i = 30 * (page - 1); i < 30 * page && i < list.length; i++) {
        page_list.push(list[i]);
    }
    return page_list;
};

const getPriceByOwner = (current_price, current_currency, current_resort_fees, prices, details, owner) => {
    if (prices === null) {
        let return_price = current_price;
        let return_currency = current_currency;
        let return_resort_fees = current_resort_fees;
        return { return_price, return_currency, return_resort_fees };
    } 
    let return_price = current_price;
    let return_currency = current_currency;
    let return_resort_fees = current_resort_fees;
    prices.map((price) => {
        if (price.owner === owner && (current_price === 0 || price.selling_price < current_price)) {
            return_price = parseFloat(price.selling_price);
            return_currency = price.selling_currency;
            return_resort_fees = details !== undefined && details !== null && !details.resort_fees_included && details.resort_fees !== 0 ? {
                fees: details.resort_fees,
                currency: details.resort_fees_currency_id
            } : null;
        }
    });
    return { return_price, return_currency, return_resort_fees };
};

export default function reducer(state = initial_state, action, test) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
        case "persist/REHYDRATE": {
            if (action.payload !== undefined && action.payload.accommodation !== undefined) {
                Object.keys(action.payload.accommodation).forEach(key => {
                    if (new_state[key] !== undefined) {
                        new_state[key] = action.payload.accommodation[key];
                    }
                });
            }
            return new_state;
        }
        case "FLUSH_REMOVE_STATE": {
            const grid_type = new_state.grid_type;
            const recommended = new_state.recommended;
            const owner = new_state.owner;
            const client_type = new_state.client_type;
            new_state = initial_state;
            new_state.grid_type = grid_type;
            new_state.recommended = recommended;
            new_state.owner = owner;
            new_state.client_type = client_type;
            return new_state;
        }
        case "CMS_FLUSH_REMOVE_STATE": {
            new_state.cart = [];
            new_state.sort_cart = [];
            return new_state;
        }
        case "SMALL_FLUSH": {
            const grid_type = new_state.grid_type;
            const recommended = new_state.recommended;
            const owner = new_state.owner;
            const client_type = new_state.client_type;
            new_state = initial_state;
            new_state.grid_type = grid_type;
            new_state.recommended = recommended;
            new_state.owner = owner;
            new_state.client_type = client_type;
            return new_state;
        }
        case 'TRIP_SET_DATES': {
            new_state.wait = true;
            return new_state;
        }
        case 'ITINERARY_SET_ITINERARY_LIST': {
            new_state.wait = false;
            new_state.destination = null;
            new_state.start_date = null;
            new_state.end_date = null;
            return new_state;
        }
        case "MATERIAL_CHANGE_CURRENT_GROUPS": {
            new_state.current_groups = action.payload;
            break;
        }
        case "MATERIAL_SET_BASIC_DATA": {
            new_state.destination = action.payload.destination;
            new_state.current_groups = action.payload.current_groups;
            new_state.room_number = action.payload.current_groups.length;
            let traveler_number = 0;
            action.payload.current_groups.map((current_group) => {
                traveler_number += current_group.travelers.length;
            });
            new_state.traveler_number = traveler_number;
            if (action.payload.bounds !== null) {
                new_state.bounds = action.payload.bounds;
            }
            new_state.start_date = action.payload.start_date;
            new_state.end_date = action.payload.end_date;
            new_state.duration_days = moment.utc(new_state.end_date).diff(moment.utc(new_state.start_date), "d");
            return new_state;
        }
        case "MATERIAL_TOGGLE_PARAMETERS": {
            new_state.show_parameters = !new_state.show_parameters;
            return new_state;
        }
        case "MATERIAL_TOGGLE_FILTERS": {
            new_state.show_filters = !new_state.show_filters;
            return new_state;
        }
        case "MATERIAL_TOGGLE_SORTS": {
            new_state.show_sorts = !new_state.show_sorts;
            return new_state;
        }
        case "MATERIAL_TOGGLE_RECOMMENDED": {
            new_state.recommended = !new_state.recommended;
            if (new_state.full_list !== null) {
                new_state.price_list = sortResults(filterResults([...new_state.full_list], new_state), new_state);
                new_state.page_list = getPageResults([...new_state.price_list], 1);
                new_state.count = new_state.price_list.length;
            }
            new_state.active_page = 1;
            return new_state;
        }
        case "MATERIAL_UPDATE_LIST": {
            const full_list = action.payload.full_list;
            if (action.payload.limit === 40) {
                new_state.run_small = false;
            } else {
                new_state.run_big = false;
            }
            new_state.run_dynamic = true;
            new_state.only_static = true;
            if (new_state.full_list === null) {
                full_list.map((hotel) => {
                    hotel.providers = [];
                    hotel.price = 0;
                    hotel.price_currency = null;
                    hotel.breakfast_price = 0;
                    hotel.breakfast_currency = null;
                    hotel.half_price = 0;
                    hotel.half_currency = null;
                    hotel.full_price = 0;
                    hotel.full_currency = null;
                    hotel.cancellable_price = 0;
                    hotel.cancellable_currency = null;
                    hotel.c_breakfast_price = 0;
                    hotel.c_breakfast_currency = null;
                    hotel.c_half_price = 0;
                    hotel.c_half_currency = null;
                    hotel.c_full_price = 0;
                    hotel.c_full_currency = null;
                    hotel.start_date = action.payload.start_date;
                    hotel.end_date = action.payload.end_date;
                });
                //new_state.full_list = sortResults(full_list, new_state);
                new_state.full_list = full_list;
                return new_state;
            }
            let new_accommodation = [];
            let old_list = [...new_state.full_list];
            full_list.map((new_hotel) => {
                let exist = false;
                for (let i = 0; i < new_state.full_list.length; i++) {
                    if (new_state.full_list[i].id === new_hotel.id) {
                        if (!(new_state.full_list[i].start_date === action.payload.start_date) || !(new_state.full_list[i].end_date === action.payload.end_date)) {
                            new_state.full_list[i].providers = [];
                            new_state.full_list[i].price = 0;
                            new_state.full_list[i].price_currency = null;
                            new_state.full_list[i].breakfast_price = 0;
                            new_state.full_list[i].breakfast_currency = null;
                            new_state.full_list[i].half_price = 0;
                            new_state.full_list[i].half_currency = null;
                            new_state.full_list[i].full_price = 0;
                            new_state.full_list[i].full_currency = null;
                            new_state.full_list[i].cancellable_price = 0;
                            new_state.full_list[i].cancellable_currency = null;
                            new_state.full_list[i].c_breakfast_price = 0;
                            new_state.full_list[i].c_breakfast_currency = null;
                            new_state.full_list[i].c_half_price = 0;
                            new_state.full_list[i].c_half_currency = null;
                            new_state.full_list[i].c_full_price = 0;
                            new_state.full_list[i].c_full_currency = null;
                            new_state.full_list[i].start_date = action.payload.start_date;
                            new_state.full_list[i].end_date = action.payload.end_date;
                        }
                        exist = true;
                        break;
                    }
                }
                if (!exist) {
                    new_accommodation.push(new_hotel);
                }
            });
            new_accommodation.map((hotel) => {
                hotel.providers = [];
                hotel.price = 0;
                hotel.price_currency = null;
                hotel.breakfast_price = 0;
                hotel.breakfast_currency = null;
                hotel.half_price = 0;
                hotel.half_currency = null;
                hotel.full_price = 0;
                hotel.full_currency = null;
                hotel.cancellable_price = 0;
                hotel.cancellable_currency = null;
                hotel.c_breakfast_price = 0;
                hotel.c_breakfast_currency = null;
                hotel.c_half_price = 0;
                hotel.c_half_currency = null;
                hotel.c_full_price = 0;
                hotel.c_full_currency = null;
                hotel.start_date = action.payload.start_date;
                hotel.end_date = action.payload.end_date;
            });
            new_state.full_list = old_list.concat(new_accommodation);
            if (new_state.full_list !== null) {
                new_state.price_list = sortResults(filterResults([...new_state.full_list], new_state), new_state);
                new_state.page_list = getPageResults([...new_state.price_list], new_state.active_page);
            }
            new_state.count = new_state.price_list.length;
            return new_state;
        }
        case "MATERIAL_SET_PRICES": {
            new_state.full_list.map((hotel) => {
                action.payload.prices.map((data) => {
                    if (hotel.id === data.hotel_id && data.prices !== undefined && data.prices !== null && data.prices.length > 0) {
                        if (hotel.catalog_price === undefined || !hotel.catalog_price) {
                            hotel.catalog_price = (data.details !== null ? data.details.catalog_price : false);
                        }
                        let already_added = false;
                        let providers = [...hotel.providers];
                        hotel.providers.map((hotel_provider) => {
                            if (hotel_provider.provider.provider.id === action.payload.provider_id) {
                                already_added = true;
                            }
                        });
                        if (!already_added) {
                            providers.push({
                                provider: action.payload.provider,
                                prices: data
                            });
                        }
                        hotel.providers = providers;
                    }
                });
            });
            new_state.active_page = 1;
            if (new_state.full_list !== null) {
                new_state.price_list = sortResults(filterResults([...new_state.full_list], new_state), new_state);
                new_state.page_list = getPageResults([...new_state.price_list], new_state.active_page);
            }
            new_state.count = new_state.price_list.length;
            new_state.providers_running_count = new_state.providers_running_count - 1;
            new_state.only_static = false;
            if (new_state.providers_running_count === 0) {
                new_state.run_dynamic = false;
            }
            return new_state;
        }
        case "MATERIAL_PROVIDER_FAILED": {
            new_state.providers_running_count = new_state.providers_running_count - 1;
            if (new_state.providers_running_count === 0) {
                new_state.run_dynamic = false;
            }
            return new_state;
        }
        case "MATERIAL_PROVIDER_RUNNING": {
            new_state.providers_running_count = new_state.providers_running_count + 1;
            return new_state;
        }
        case "MATERIAL_STATIC_RUNNING": {
            new_state.static_running_count = new_state.static_running_count + 1;
            return new_state;
        }
        case "MATERIAL_STATIC_RAN": {
            new_state.static_running_count = new_state.static_running_count - 1;
            return new_state;
        }
        case "MATERIAL_DYNAMIC_RAN": {
            new_state.run_dynamic = false;
            return new_state;
        }
        case "MATERIAL_SORT_LIST": {
            new_state.sort = action.payload.value;
            if (new_state.full_list !== null) {
                new_state.price_list = sortResults(filterResults([...new_state.full_list], new_state), new_state);
                new_state.page_list = getPageResults([...new_state.price_list], 1);
            }
            new_state.active_page = 1;
            new_state.show_sorts = false;
            return new_state;
        }
        case "MATERIAL_SET_ACTIVE_PAGE": {
            new_state.active_page = action.payload.active_page;
            new_state.page_list = getPageResults([...new_state.price_list], action.payload.active_page);
            return new_state;
        }
        case "MATERIAL_FILTER_LIST": {
            new_state.star_1 = action.payload.star_1;
            new_state.star_2 = action.payload.star_2;
            new_state.star_3 = action.payload.star_3;
            new_state.star_4 = action.payload.star_4;
            new_state.star_5 = action.payload.star_5;
            new_state.check_in = action.payload.check_in;
            new_state.bar = action.payload.bar;
            new_state.restaurant = action.payload.restaurant;
            new_state.kitchen = action.payload.kitchen;
            new_state.change = action.payload.change;
            new_state.receptionist = action.payload.receptionist;
            new_state.elevator = action.payload.elevator;
            new_state.parking = action.payload.parking;
            new_state.swimming_pool = action.payload.swimming_pool;
            new_state.room_service = action.payload.room_service;
            new_state.private_beach = action.payload.private_beach;
            new_state.television = action.payload.television;
            new_state.safe = action.payload.safe;
            new_state.wifi = action.payload.wifi;
            new_state.air_conditioning = action.payload.air_conditioning;
            new_state.mini_bar = action.payload.mini_bar;
            new_state.conferencerooms = action.payload.conferencerooms;
            new_state.spa = action.payload.spa;
            new_state.selected_providers = action.payload.selected_providers;
            new_state.meal_plan = action.payload.meal_plan;
            new_state.cancellable = action.payload.cancellable;
            new_state.resort_fees = action.payload.resort_fees;
            if (new_state.full_list !== null) {
                new_state.price_list = sortResults(filterResults([...new_state.full_list], new_state), new_state);
                new_state.page_list = getPageResults([...new_state.price_list], 1);
            }
            if (new_state.price_list !== null) {
                new_state.count = new_state.price_list.length;
            }
            new_state.active_page = 1;
            new_state.show_filters = false;
            new_state.active_filters = 0;
            Object.keys(action.payload).forEach((key, index) => {
                if (key === 'selected_providers') {
                    new_state.active_filters += action.payload[key].length;
                } else if (key === 'meal_plan') {
                    if (action.payload[key] !== null) {
                        new_state.active_filters += 1;
                    }
                } else if (action.payload[key]) {
                    new_state.active_filters += 1;
                }
            });
            return new_state;
        }
        case "MATERIAL_SET_MEAL_PLAN": {
            new_state.meal_plan = action.payload;
            if (new_state.full_list !== null) {
                new_state.price_list = sortResults(filterResults([...new_state.full_list], new_state), new_state);
                new_state.page_list = getPageResults([...new_state.price_list], 1);
            }
            new_state.count = new_state.price_list.length;
            new_state.active_filters = action.payload === null ? new_state.active_filters - 1 : new_state.active_filters + 1;
            new_state.active_page = 1;
            return new_state;
        }
        case "MATERIAL_SET_SELECTED_PROVIDERS": {
            new_state.selected_providers = action.payload;
            if (new_state.full_list !== null) {
                new_state.price_list = sortResults(filterResults([...new_state.full_list], new_state), new_state);
                new_state.page_list = getPageResults([...new_state.price_list], 1);
            }
            new_state.count = new_state.price_list.length;
            new_state.active_page = 1;
            new_state.active_filters = action.payload.length === 0 ? new_state.active_filters - 1 : new_state.active_filters + 1;
            return new_state;
        }
        case "MATERIAL_SET_CANCELLABLE": {
            new_state.cancellable = action.payload;
            return new_state;
        }
        case "MATERIAL_UPDATE_HOVERED": {
            new_state.hovered = action.payload.hovered;
            return new_state;
        }
        case "MATERIAL_UPDATE_SEEN": {
            let already_seen = false;
            new_state.seen.map((seen) => {
                if (seen === action.payload.id) {
                    already_seen = true;
                }
            });
            if (!already_seen) {
                new_state.seen.push(action.payload.id);
            }
            return new_state;
        }
        case "MATERIAL_TOGGLE_AUTO_RELOAD": {
            new_state.auto_reload = !new_state.auto_reload;
            return new_state;
        }
        case "MATERIAL_UPDATE_MAP": {
            let accommodation_origin = false;
            action.payload.origin.map((origin) => {
                if (origin.path === "accommodation") {
                    accommodation_origin = true;
                }
            });
            if (accommodation_origin) {
                if (new_state.card_unmount) {
                    new_state.card_unmount = false;
                } else if (new_state.auto_reload) {
                    new_state.bounds = action.payload.bounds;
                    if (new_state.full_list !== null) {
                        new_state.price_list = filterResults([...new_state.full_list], new_state);
                        new_state.page_list = getPageResults([...new_state.price_list], 1);
                        new_state.count = new_state.price_list.length;
                    }
                    new_state.active_page = 1;
                    if (!new_state.run_small) {
                        new_state.run_small = true;
                    }
                    if (!new_state.run_big) {
                        new_state.run_big = true;
                    }
                }
            }
            return new_state;
        }
        case "MATERIAL_APPLY_MOVED": {
            new_state.bounds = action.payload.bounds;
            if (new_state.full_list !== null) {
                new_state.price_list = filterResults([...new_state.full_list], new_state);
                new_state.page_list = getPageResults([...new_state.price_list], 1);
                new_state.count = new_state.price_list.length;
            }
            new_state.active_page = 1;
            if (!new_state.run_small) {
                new_state.run_small = true;
            }
            if (!new_state.run_big) {
                new_state.run_big = true;
            }
            return new_state;
        }
        case "MATERIAL_UPDATE_PARAMETERS": {
            if (action.payload.late === "arrival") {
                new_state.choose_late_arrival = true;
            } else if (action.payload.late === "departure") {
                new_state.choose_late_departure = true;
            }
            new_state.start_date = action.payload.start_date;
            new_state.end_date = action.payload.end_date;
            new_state.current_groups = action.payload.current_groups;
            new_state.room_number = action.payload.current_groups.length;
            let traveler_number = 0;
            action.payload.current_groups.map((current_group) => {
                traveler_number += current_group.travelers.length;
            });
            new_state.traveler_number = traveler_number;
            new_state.full_list = null;
            new_state.price_list = null;
            new_state.page_list = null;
            new_state.count = 0;
            new_state.run_big = true;
            new_state.run_small = true;
            new_state.duration_days = moment(new_state.end_date).diff(moment(new_state.start_date), "d");
            new_state.show_parameters = false;
            return new_state;
        }
        case "TRIP_SET_TRAVELER_GROUPS": {
            let current_groups = [];
            action.payload.traveler_groups.map((groups) => {
                if (groups.default) {
                    current_groups.push(groups);
                }
            });
            new_state.current_groups = current_groups;
            new_state.room_number = current_groups.length;
            let traveler_number = 0;
            current_groups.map((current_group) => {
                traveler_number += current_group.travelers.length;
            });
            new_state.traveler_number = traveler_number;
            new_state.full_list = null;
            new_state.price_list = null;
            new_state.page_list = null;
            new_state.count = 0;
            new_state.run_big = true;
            new_state.run_small = true;
            return new_state;
        }
        case "MATERIAL_SEARCH": {
            new_state.search = action.payload.value;
            if (new_state.full_list !== null) {
                new_state.price_list = sortResults(filterResults([...new_state.full_list], new_state), new_state);
                new_state.page_list = getPageResults([...new_state.price_list], 1);
                new_state.count = new_state.price_list.length;
            }
            new_state.run_small = true;
            new_state.active_page = 1;
            return new_state;
        }
        case "MATERIAL_SET_ACTIVE_DESTINATION": {
            new_state.start_date = action.payload.start_date;
            new_state.end_date = action.payload.end_date;
            new_state.duration_days = moment(new_state.end_date).diff(moment(new_state.start_date), "d");
            new_state.destination = action.payload.destination;
            new_state.run_big = false;
            new_state.run_small = false;
            new_state.run_dynamic = false;
            new_state.search = "";
            if (action.payload.reset) {
                if (new_state.full_list !== null) {
                    new_state.price_list = filterResults([...new_state.full_list], new_state);
                    new_state.page_list = getPageResults([...new_state.price_list], 1);
                    new_state.count = new_state.price_list.length;
                } else {
                    new_state.price_list = null;
                    new_state.page_list = null;
                    new_state.count = 0;
                }
            }
            new_state.bounds = action.payload.bounds;
            new_state.hovered = null;
            return new_state;
        }
        case "MATERIAL_ACCOMMODATION_CARD_UNMOUNT": {
            new_state.start_date = action.payload.start_date;
            new_state.end_date = action.payload.end_date;
            new_state.duration_days = moment(new_state.end_date).diff(moment(new_state.start_date), "d");
            new_state.destination = action.payload.destination;
            new_state.current_groups = action.payload.current_groups;
            new_state.room_number = action.payload.current_groups.length;
            new_state.search = '';
            let traveler_number = 0;
            action.payload.current_groups.map((current_group) => {
                traveler_number += current_group.travelers.length;
            });
            new_state.traveler_number = traveler_number;
            if (action.payload.reset) {
                new_state.page_list = null;
            }
            new_state.hovered = null;
            new_state.replace_id = null;
            return new_state;
        }
        case "MATERIAL_ACCOMMODATION_CARD_UNMOUNT_BACK": {
            new_state.card_unmount = true;
            new_state.hovered = null;
            new_state.replace_id = null;
            return new_state;
        }
        case "MATERIAL_SET_CANCELLATION": {
            new_state.cancellation_info = action.payload.charges;
            new_state.cancellation_info = new_state.cancellation_info.sort((a, b) => {
                if (a.from_day === null) {
                    return -1;
                } else if (b.from_day === null) {
                    return 1;
                }
                return b.from_day - a.from_day;
            });
            new_state.cancellation_currency = action.payload.charges_currency;
            new_state.open_cancellation_info = true;
            return new_state;
        }
        case "MATERIAL_SET_CANCELLATION_TEXT": {
            new_state.cancellation_text = action.payload;
            return new_state;
        }
        case "MATERIAL_CLOSE_CANCELLATION": {
            new_state.cancellation_info = [];
            new_state.cancellation_currency = null;
            new_state.open_cancellation_info = false;
            return new_state;
        }
        case "MATERIAL_TOGGLE_GRID_TYPE": {
            new_state.grid_type = new_state.grid_type === 1 ? 2 : new_state.grid_type === 2 ? 3 : new_state.grid_type === 3 && !action.payload ? 4 : 1;
            return new_state;
        }
        case "MATERIAL_SET_ADDITIONAL_INFO": {
            new_state.additional_info = action.payload;
            return new_state;
        }
        case "MATERIAL_SET_CHECK_IN_INFO": {
            new_state.check_in_info = action.payload;
            return new_state;
        }
        case "MATERIAL_TOGGLE_TAXES_INFO": {
            new_state.taxes_info = !new_state.taxes_info;
            return new_state;
        }
        case "USER_SET_USER": {
            new_state.owner = action.payload.user.client_full.id;
            new_state.client_type = action.payload.user.client_full.type;
            return new_state;
        }
        case "USER_SET_USER_AND_CONNECTION": {
            new_state.owner = action.payload.user.client_full.id;
            new_state.client_type = action.payload.user.client_full.type;
            return new_state;
        }
        case "USER_SET_CONNECTION": {
            new_state.owner = action.payload.user !== null ? action.payload.user.client_full.id : null;
            new_state.client_type = action.payload.user !== null ? action.payload.user.client_full.type : null;
            return new_state;
        }
        case "MATERIAL_RESET_REPLACE_ID": {
            new_state.replace_id = null;
            return new_state;
        }
        case "ACCOMMODATION_REPLACE_ROOM": {
            new_state.start_date = moment(action.payload.start_date).format('YYYY-MM-DD');
            new_state.end_date = moment(action.payload.end_date).format('YYYY-MM-DD');
            new_state.current_groups = [];
            new_state.current_groups.push(action.payload.group_passenger);
            new_state.room_number = 1;
            new_state.traveler_number = action.payload.group_passenger.travelers.length;
            new_state.full_list = null;
            new_state.price_list = null;
            new_state.page_list = null;
            new_state.count = 0;
            new_state.run_big = true;
            new_state.run_small = true;
            new_state.duration_days = moment(new_state.end_date).diff(moment(new_state.start_date), "d");
            new_state.show_parameters = false;
            new_state.replace_id = action.payload.id;
            return new_state;
        }
        case 'ACCOMMODATION_GO_TO_CARD': {
            new_state.start_date = moment(action.payload.start_date).format('YYYY-MM-DD');
            new_state.end_date = moment(action.payload.end_date).format('YYYY-MM-DD');
            new_state.current_groups = action.payload.current_groups;
            new_state.room_number = new_state.current_groups.length;
            new_state.traveler_number = action.payload.traveler_number;
            new_state.full_list = null;
            new_state.price_list = null;
            new_state.page_list = null;
            new_state.count = 0;
            new_state.run_big = true;
            new_state.run_small = true;
            new_state.duration_days = moment(new_state.end_date).diff(moment(new_state.start_date), "d");
            new_state.show_parameters = false;
            return new_state;
        }

        case "ACCOMMODATION_ADD_TO_CART": {
            new_state.current_cart = [];
            new_state.cart = [].concat(new_state.cart, addProviderDates(action.payload.room));
            new_state.cart = new_state.cart.sort(SortByKey(true, "start_date"));
            let sort_cart = [];
            new_state.cart.map(item => {
                let same = false;
                sort_cart.map(sort_item => {
                    if (item.is_displayed && sort_item.hotel[0].id === item.hotel[0].id && (sort_item.provider === item.provider || new_state.client_type === 2) && sort_item.start_date === item.start_date &&
                        sort_item.end_date === item.end_date && sort_item.on_request === item.on_request && sort_item.is_optional === item.is_optional && sort_item.option === item.option && sort_item.variant === item.variant &&
                        sort_item.is_available === item.is_available &&
                        ((sort_item.booking_status === null && item.booking_status === null) || (sort_item.booking_status !== null && item.booking_status !== null &&
                            ((sort_item.booking_status.status_booking !== 'CANCELLED' && item.booking_status.status_booking !== 'CANCELLED' && sort_item.booking_process_state !== 'CANCEL' && item.booking_process_state !== 'CANCEL') ||
                                (sort_item.booking_status.status_booking === 'CANCELLED' && item.booking_status.status_booking === 'CANCELLED'))) && ((sort_item.booking_status.status_booking !== 'ERROR' && item.booking_status.status_booking !== 'ERROR') ||
                                (sort_item.booking_status.status_booking === 'ERROR' && item.booking_status.status_booking === 'ERROR')))) {
                        same = true;
                        sort_item.rooms.push(item);
                        sort_item.rooms = sort_item.rooms.sort((a, b) => {
                            return a.room_ordering - b.room_ordering;
                        });
                    }
                });
                if (!same) {
                    sort_cart.push({ ...item, rooms: [item] });
                }
            });
            new_state.sort_cart = sort_cart;
            /*
            let cart_cpy = new_state.sort_cart;
            let same = false;
            for (let i = 0; i < cart_cpy.length; i++) {
                if (cart_cpy[i].hotel[0].id === action.payload.room.hotel[0].id && action.payload.room.start_date === cart_cpy[i].start_date && action.payload.room.end_date === cart_cpy[i].end_date && cart_cpy[i].is_optional === action.payload.room.is_optional && cart_cpy[i].variant === action.payload.room.variant && cart_cpy[i].provider === action.payload.room.provider){
                    if (cart_cpy[i].booking_status === null || (cart_cpy[i].booking_status !== null && cart_cpy[i].booking_status.status_booking !== "CANCELLED" && cart_cpy[i].booking_process_state !== "CANCEL")) {
                        same = true;
                        cart_cpy[i].rooms.push(action.payload.room);
                    }
                }
            }
            if (same === true)
                new_state.sort_cart = cart_cpy;
            new_state.cart = [].concat(new_state.cart, action.payload.room);
            if (same === false) {
                action.payload.room.rooms = [];
                new_state.sort_cart = [].concat(new_state.sort_cart, action.payload.room);
            }
             */
            new_state.index_current_group = 0;
            new_state.current_provider = null;
            new_state.current_rooms = null;
            new_state.replace_id = null;
            return new_state;
        }
        case 'ACCOMMODATION_UPDATE_CART': {
            new_state.current_cart = [];
            let cart = [...new_state.cart];
            for (let i = 0; i < cart.length; i++) {
                action.payload.map(item => {
                    item.map(new_room => {
                        if (cart[i].id === new_room.id) {
                            cart[i] = addProviderDates(new_room);
                        }
                    });
                });
            }
            new_state.cart = cart;
            new_state.cart = new_state.cart.sort(SortByKey(true, "start_date"));
            let sort_cart = [];
            new_state.cart.map(item => {
                let same = false;
                sort_cart.map(sort_item => {
                    if (item.is_displayed && sort_item.hotel[0].id === item.hotel[0].id && (sort_item.provider === item.provider || new_state.client_type === 2) && sort_item.start_date === item.start_date &&
                        sort_item.end_date === item.end_date && sort_item.on_request === item.on_request && sort_item.is_optional === item.is_optional && sort_item.option === item.option && sort_item.variant === item.variant &&
                        sort_item.is_available === item.is_available &&
                        ((sort_item.booking_status === null && item.booking_status === null) || (sort_item.booking_status !== null && item.booking_status !== null &&
                            ((sort_item.booking_status.status_booking !== 'CANCELLED' && item.booking_status.status_booking !== 'CANCELLED' && sort_item.booking_process_state !== 'CANCEL' && item.booking_process_state !== 'CANCEL') ||
                                (sort_item.booking_status.status_booking === 'CANCELLED' && item.booking_status.status_booking === 'CANCELLED'))) && ((sort_item.booking_status.status_booking !== 'ERROR' && item.booking_status.status_booking !== 'ERROR') ||
                                (sort_item.booking_status.status_booking === 'ERROR' && item.booking_status.status_booking === 'ERROR')))) {
                        same = true;
                        sort_item.rooms.push(item);
                        sort_item.rooms = sort_item.rooms.sort((a, b) => {
                            return a.room_ordering - b.room_ordering;
                        });
                    }
                });
                if (!same) {
                    sort_cart.push({ ...item, rooms: [item] });
                }
            });
            new_state.sort_cart = sort_cart;
            new_state.index_current_group = 0;
            new_state.current_provider = null;
            new_state.current_rooms = null;
            new_state.replace_id = null;
            return new_state;
        }
        case "ACCOMMODATION_REMOVE_FROM_CART": {
            let sort_cart = new_state.sort_cart.slice();
            let cart = new_state.cart.slice();
            if (sort_cart[action.payload.index].rooms.length === 0 || sort_cart[action.payload.index].rooms === undefined) {
                for (let i = 0; i < cart.length; i++) {
                    if (cart[i].id === sort_cart[action.payload.index].id && cart[i].start_date === sort_cart[action.payload.index].start_date && cart[i].end_date === sort_cart[action.payload.index].end_date && cart[i].provider === sort_cart[action.payload.index].provider) {
                        cart.splice(i, 1);
                    }
                }
            } else {
                for (let i = 0; i < cart.length; i++) {
                    for (let j = 0; j < sort_cart[action.payload.index].rooms.length; j++) {
                        if (sort_cart[action.payload.index].rooms[j].id === cart[i].id && sort_cart[action.payload.index].rooms[j].start_date === cart[i].start_date && sort_cart[action.payload.index].rooms[j].end_date === cart[i].end_date && sort_cart[action.payload.index].rooms[j].provider === cart[i].provider) {
                            cart.splice(i, 1);
                        }
                    }
                }
                for (let i = 0; i < cart.length; i++) {
                    if (cart[i].id === sort_cart[action.payload.index].id && cart[i].start_date === sort_cart[action.payload.index].start_date && cart[i].end_date === sort_cart[action.payload.index].end_date && cart[i].provider === sort_cart[action.payload.index].provider) {
                        cart.splice(i, 1);
                    }
                }
            }
            sort_cart.splice(action.payload.index, 1);
            new_state.cart = cart;
            new_state.sort_cart = sort_cart;
            new_state.replace_id = null;
            return new_state;
        }
        case "ACCOMMODATION_REMOVE_FROM_CART_BY_ID": {
            let cart = [];
            for (let i = 0; i < new_state.cart.length; i++) {
                if (new_state.cart[i].id !== action.payload) {
                    cart.push(new_state.cart[i]);
                }
            }
            new_state.cart = cart.slice();
            let sort_cart = [];
            new_state.cart.map(item => {
                let same = false;
                sort_cart.map(sort_item => {
                    if (item.is_displayed && sort_item.hotel[0].id === item.hotel[0].id && (sort_item.provider === item.provider || new_state.client_type === 2) && sort_item.start_date === item.start_date &&
                        sort_item.end_date === item.end_date && sort_item.on_request === item.on_request && sort_item.is_optional === item.is_optional && sort_item.option === item.option && sort_item.variant === item.variant &&
                        sort_item.is_available === item.is_available &&
                        ((sort_item.booking_status === null && item.booking_status === null) || (sort_item.booking_status !== null && item.booking_status !== null &&
                            ((sort_item.booking_status.status_booking !== 'CANCELLED' && item.booking_status.status_booking !== 'CANCELLED' && sort_item.booking_process_state !== 'CANCEL' && item.booking_process_state !== 'CANCEL') ||
                                (sort_item.booking_status.status_booking === 'CANCELLED' && item.booking_status.status_booking === 'CANCELLED'))) && ((sort_item.booking_status.status_booking !== 'ERROR' && item.booking_status.status_booking !== 'ERROR') ||
                                (sort_item.booking_status.status_booking === 'ERROR' && item.booking_status.status_booking === 'ERROR')))) {
                        same = true;
                        sort_item.rooms.push(item);
                        sort_item.rooms = sort_item.rooms.sort((a, b) => {
                            return a.room_ordering - b.room_ordering;
                        });
                    }
                });
                if (!same) {
                    sort_cart.push({ ...item, rooms: [item] });
                }
            });
            new_state.sort_cart = sort_cart;
            /*
            if (cart.length > 0) {
                let new_acc_cart = [];
                cart[0].rooms = [];
                new_acc_cart.push(cart[0]);
                for (let j = 1; j < cart.length; j++) {
                    let cart_cpy = new_acc_cart.slice();
                    let same = false;
                    for (let i = 0; i < cart_cpy.length; i++) {
                        if(cart_cpy[i].hotel[0].id === cart[j].hotel[0].id && cart_cpy[i].provider === cart[j].provider && cart[j].start_date === cart_cpy[i].start_date && cart[j].end_date === cart_cpy[i].end_date && cart_cpy[i].is_optional === cart[j].is_optional && cart_cpy[i].variant === cart[j].variant){
                            same = true;
                            cart_cpy[i].rooms.push(cart[j]);
                        }
                    }
                    if (same === true)
                        new_acc_cart = cart_cpy;
                    if (same === false) {
                        cart[j].rooms = [];
                        new_acc_cart.push(cart[j]);
                    }
                }
                new_state.sort_cart = new_acc_cart;
            } else {
                new_state.sort_cart = [];
            }
             */
            new_state.replace_id = null;
            return new_state;
        }
        case "ACCOMMODATION_SET_CART": {
            new_state.cart = action.payload.cart.map((item) => {
                return addProviderDates(item);
            });
            let sort_cart = [];
            new_state.cart.map(item => {
                let same = false;
                sort_cart.map(sort_item => {
                    if (item.is_displayed && sort_item.hotel[0].id === item.hotel[0].id && (sort_item.provider === item.provider || new_state.client_type === 2) && sort_item.start_date === item.start_date &&
                        sort_item.end_date === item.end_date && sort_item.on_request === item.on_request && sort_item.is_optional === item.is_optional && sort_item.option === item.option && sort_item.variant === item.variant &&
                        sort_item.is_available === item.is_available &&
                        ((sort_item.booking_status === null && item.booking_status === null) || (sort_item.booking_status !== null && item.booking_status !== null &&
                            ((sort_item.booking_status.status_booking !== 'CANCELLED' && item.booking_status.status_booking !== 'CANCELLED' && sort_item.booking_process_state !== 'CANCEL' && item.booking_process_state !== 'CANCEL') ||
                                (sort_item.booking_status.status_booking === 'CANCELLED' && item.booking_status.status_booking === 'CANCELLED'))) && ((sort_item.booking_status.status_booking !== 'ERROR' && item.booking_status.status_booking !== 'ERROR') ||
                                (sort_item.booking_status.status_booking === 'ERROR' && item.booking_status.status_booking === 'ERROR')))) {
                        same = true;
                        sort_item.rooms.push(item);
                        sort_item.rooms = sort_item.rooms.sort((a, b) => {
                            return a.room_ordering - b.room_ordering;
                        });
                    }
                });
                if (!same) {
                    sort_cart.push({ ...item, rooms: [item] });
                }
            });
            if (new_state.refresh !== null) {
                Object.keys(new_state.refresh).map(key => {
                    sort_cart.map(item => {
                        for (let i = 0; i < item.rooms.length; i++) {
                            if (item.rooms[i].id === parseInt(key)) {
                                let room = { ...item.rooms[i] };
                                room.price_change = new_state.refresh[key];
                                item.rooms[i] = room;
                            }
                        }
                    });
                });
            }
            new_state.sort_cart = sort_cart;
            new_state.replace_id = null;
            /*
            if (action.payload.cart !== null && action.payload.cart.length > 0) {
                let new_acc_cart = [];
                let index = 0;
                for (let k = 0; k < action.payload.cart.length; k++) {
                    action.payload.cart[k].rooms = [];
                    if (action.payload.cart[k].is_displayed === true) {
                        new_acc_cart.push(action.payload.cart[k]);
                        index = k + 1;
                        break;
                   }
                }
                for (let j = index; j < action.payload.cart.length; j++) {
                    let cart_cpy = new_acc_cart.slice();
                    let same = false;
                    for (let i = 0; i < cart_cpy.length; i++) {
                        if(action.payload.cart[j].is_displayed === true && cart_cpy[i].hotel[0].id === action.payload.cart[j].hotel[0].id && cart_cpy[i].provider === action.payload.cart[j].provider && action.payload.cart[j].start_date === cart_cpy[i].start_date && action.payload.cart[j].end_date === cart_cpy[i].end_date && cart_cpy[i].is_optional === action.payload.cart[j].is_optional && cart_cpy[i].variant === action.payload.cart[j].variant){
                            if ((cart_cpy[i].booking_status === null || (cart_cpy[i].booking_status !== null && cart_cpy[i].booking_status.status_booking !== "CANCELLED" && cart_cpy[i].booking_process_state !== "CANCEL")) && (action.payload.cart[j].booking_status === null || (action.payload.cart[j].booking_status !== null && action.payload.cart[j].booking_status.status_booking !== "CANCELLED" && action.payload.cart[j].booking_process_state !== "CANCEL")) &&
                                (!(cart_cpy[i].booking_status !== null && action.payload.cart[j].booking_status === null) && !(cart_cpy[i].booking_status === null && action.payload.cart[j].booking_status !== null))) {
                                same = true;
                                cart_cpy[i].rooms.push(action.payload.cart[j]);
                            }
                        }
                    }
                    if (same === true)
                        new_acc_cart = cart_cpy;
                    if (same === false && action.payload.cart[j].is_displayed === true ) {
                        action.payload.cart[j].rooms = [];
                        new_acc_cart.push(action.payload.cart[j]);
                    }
                }SORT_CART
                new_state.sort_cart = new_acc_cart;
            }
             */
            return new_state;
        }
        case "ACCOMMODATION_SET_MANUAL_MARGIN_EDIT" : {
            let cart = new_state.sort_cart.slice();
            let copy_cart = [];
            cart.map((item, item_index) => {
                if (item_index === action.payload.index) {
                    let copy_item = Object.assign({}, item);
                    copy_item.edit_margin = action.payload.edit;
                    item = copy_item;
                }
                copy_cart.push(item);
            });
            new_state.sort_cart = copy_cart;
            return new_state;
        }
        case "ACCOMMODATION_SET_SORT_CART": {
            new_state.sort_cart = action.payload.sort_cart;
            return new_state;
        }
        case "ACCOMMODATION_EDIT_CART_FROM_BDD": {
            let cart = new_state.cart.slice();
            cart[action.payload.index] = action.payload.room;
            new_state.cart = cart;
            let sort_cart = [];
            new_state.cart.map(item => {
                let same = false;
                sort_cart.map(sort_item => {
                    if (item.is_displayed && sort_item.hotel[0].id === item.hotel[0].id && (sort_item.provider === item.provider || new_state.client_type === 2) && sort_item.start_date === item.start_date &&
                        sort_item.end_date === item.end_date && sort_item.on_request === item.on_request && sort_item.is_optional === item.is_optional && sort_item.option === item.option && sort_item.variant === item.variant &&
                        sort_item.is_available === item.is_available &&
                        ((sort_item.booking_status === null && item.booking_status === null) || (sort_item.booking_status !== null && item.booking_status !== null &&
                            ((sort_item.booking_status.status_booking !== 'CANCELLED' && item.booking_status.status_booking !== 'CANCELLED' && sort_item.booking_process_state !== 'CANCEL' && item.booking_process_state !== 'CANCEL') ||
                                (sort_item.booking_status.status_booking === 'CANCELLED' && item.booking_status.status_booking === 'CANCELLED'))) && ((sort_item.booking_status.status_booking !== 'ERROR' && item.booking_status.status_booking !== 'ERROR') ||
                                (sort_item.booking_status.status_booking === 'ERROR' && item.booking_status.status_booking === 'ERROR')))) {
                        same = true;
                        sort_item.rooms.push(item);
                        sort_item.rooms = sort_item.rooms.sort((a, b) => {
                            return a.room_ordering - b.room_ordering;
                        });
                    }
                });
                if (!same) {
                    sort_cart.push({ ...item, rooms: [item] });
                }
            });
            new_state.sort_cart = sort_cart;
            /*
            if (cart !== null && cart.length > 0) {
                let new_acc_cart = [];
                let index = 0;
                for (let j = index; j < cart.length; j++) {
                    let cart_cpy = new_acc_cart.slice();
                    let same = false;
                    let same_product = false;
                    for (let i = 0; i < cart_cpy.length; i++) {
                        if (cart_cpy[i].id === cart[j].id) {
                            same_product = true;
                        }
                        if(cart[j].is_displayed === true && cart_cpy[i].hotel[0].id === cart[j].hotel[0].id && cart_cpy[i].provider === cart[j].provider && cart_cpy[i].id !== cart[j].id && cart[j].start_date === cart_cpy[i].start_date && cart[j].end_date === cart_cpy[i].end_date ){
                            if ((cart_cpy[i].booking_status === null || (cart_cpy[i].booking_status !== null && cart_cpy[i].booking_status.status_booking !== "CANCELLED" && cart_cpy[i].booking_process_state !== "CANCEL")) &&
                                (cart[j].booking_status === null || (cart[j].booking_status !== null && cart[j].booking_status.status_booking !== "CANCELLED" && cart[j].booking_process_state !== "CANCEL")) &&
                                (!(cart_cpy[i].booking_status !== null && cart[j].booking_status === null) && !(cart_cpy[i].booking_status === null && cart[j].booking_status !== null))) {
                                same = true;
                                cart_cpy[i].rooms.push(cart[j]);
                            }
                        }
                    }
                    if (same === true)
                        new_acc_cart = cart_cpy;
                    if (same === false && cart[j].is_displayed === true && !same_product ) {
                        cart[j].rooms = [];
                        new_acc_cart.push(cart[j]);
                    }
                }
                new_state.sort_cart = new_acc_cart;
            }
             */
            return new_state;
        }
        case "ACCOMMODATION_EDIT_CART_BY_ID": {
            let cart = new_state.cart.slice();
            for (let i = 0; i < cart.length; i++) {
                if (cart[i].id === action.payload.id) {
                    cart[i] = addProviderDates(action.payload);
                }
            }
            new_state.cart = cart;
            let sort_cart = [];
            new_state.cart.map(item => {
                let same = false;
                sort_cart.map(sort_item => {
                    if (item.is_displayed && sort_item.hotel[0].id === item.hotel[0].id && (sort_item.provider === item.provider || new_state.client_type === 2) && sort_item.start_date === item.start_date &&
                        sort_item.end_date === item.end_date && sort_item.on_request === item.on_request && sort_item.is_optional === item.is_optional && sort_item.option === item.option && sort_item.variant === item.variant &&
                        sort_item.is_available === item.is_available &&
                        ((sort_item.booking_status === null && item.booking_status === null) || (sort_item.booking_status !== null && item.booking_status !== null &&
                            ((sort_item.booking_status.status_booking !== 'CANCELLED' && item.booking_status.status_booking !== 'CANCELLED' && sort_item.booking_process_state !== 'CANCEL' && item.booking_process_state !== 'CANCEL') ||
                                (sort_item.booking_status.status_booking === 'CANCELLED' && item.booking_status.status_booking === 'CANCELLED'))) && ((sort_item.booking_status.status_booking !== 'ERROR' && item.booking_status.status_booking !== 'ERROR') ||
                                (sort_item.booking_status.status_booking === 'ERROR' && item.booking_status.status_booking === 'ERROR')))) {
                        same = true;
                        sort_item.rooms.push(item);
                        sort_item.rooms = sort_item.rooms.sort((a, b) => {
                            return a.room_ordering - b.room_ordering;
                        });
                    }
                });
                if (!same) {
                    sort_cart.push({ ...item, rooms: [item] });
                }
            });
            new_state.sort_cart = sort_cart;
            /*
            if (cart.length > 0) {
                let new_acc_cart = [];
                let index = 0;
                for (let j = index; j < cart.length; j++) {
                    let cart_cpy = new_acc_cart.slice();
                    let same = false;
                    let same_product = false;
                    for (let i = 0; i < cart_cpy.length; i++) {
                        if (cart_cpy[i].id === cart[j].id) {
                            same_product = true;
                        }
                        if(cart[j].is_displayed === true && cart_cpy[i].hotel[0].id === cart[j].hotel[0].id && cart_cpy[i].provider === cart[j].provider && cart_cpy[i].id !== cart[j].id && cart[j].start_date === cart_cpy[i].start_date && cart[j].end_date === cart_cpy[i].end_date ){
                            if ((cart_cpy[i].booking_status === null || (cart_cpy[i].booking_status !== null && cart_cpy[i].booking_status.status_booking !== "CANCELLED" && cart_cpy[i].booking_process_state !== "CANCEL")) &&
                                (cart[j].booking_status === null || (cart[j].booking_status !== null && cart[j].booking_status.status_booking !== "CANCELLED" && cart[j].booking_process_state !== "CANCEL"))) {
                                same = true;
                                cart_cpy[i].rooms.push(cart[j]);
                            }
                        }
                    }
                    if (same === true)
                        new_acc_cart = cart_cpy;
                    if (same === false && cart[j].is_displayed === true && !same_product ) {
                        cart[j].rooms = [];
                        new_acc_cart.push(cart[j]);
                    }
                }
                new_state.sort_cart = new_acc_cart;
            }
             */
            return new_state;
        }
        case "ACCOMMODATION_EDIT_CART": {
            let sort_cart = new_state.sort_cart.slice();
            let cart = new_state.cart.slice();
            if (sort_cart[action.payload.index].rooms.length === 0 || sort_cart[action.payload.index].rooms === undefined) {
                for (let i = 0; i < cart.length; i++) {
                    if (cart[i].id === sort_cart[action.payload.index].id && cart[i].provider === sort_cart[action.payload.index].provider && cart[i].start_date === sort_cart[action.payload.index].start_date && cart[i].end_date === sort_cart[action.payload.index].end_date) {
                        cart[i] = action.payload.room;
                    }
                }
            } else {
                for (let i = 0; i < cart.length; i++) {
                    if (cart[i].id === sort_cart[action.payload.index].id && cart[i].provider === sort_cart[action.payload.index].provider && cart[i].start_date === sort_cart[action.payload.index].start_date && cart[i].end_date === sort_cart[action.payload.index].end_date) {
                        cart[i] = action.payload.room;
                    }
                    for (let j = 0; j < sort_cart[action.payload.index].rooms.length; j++) {
                        if (sort_cart[action.payload.index].rooms[j].id === cart[i].id && sort_cart[action.payload.index].rooms[j].provider === cart[i].provider && sort_cart[action.payload.index].rooms[j].start_date === cart[i].start_date && sort_cart[action.payload.index].rooms[j].end_date === cart[i].end_date) {
                            cart[i] = sort_cart[action.payload.index].rooms[j];
                        }
                    }
                }
            }
            sort_cart[action.payload.index] = action.payload.room;
            new_state.cart = cart;
            new_state.sort_cart = sort_cart;
            return new_state;
        }
        case "ACCOMMODATION_EDIT_STATUS": {
            let cart = new_state.cart.slice();
            let sort_cart = new_state.sort_cart.slice();
            let copy_cart = [];
            let copy_sort_cart = [];
            cart.map((tmp_cart) => {
                if (action.payload.data.id === tmp_cart.id) {
                    let copy_tmp_cart = Object.assign({}, tmp_cart);
                    copy_tmp_cart.booking_status = action.payload.data.booking_status;
                    tmp_cart = copy_tmp_cart;
                }
                copy_cart.push(tmp_cart);
            });
            sort_cart.map((accommodation) => {
                if (action.payload.data.id === accommodation.id) {
                    let copy_accommodation = Object.assign({}, accommodation);
                    copy_accommodation.booking_status = action.payload.data.booking_status;
                    accommodation = copy_accommodation;
                } else if (action.payload.data.id !== accommodation.id && accommodation.rooms.length > 0) {
                    let copy_accommodation = Object.assign({}, accommodation);
                    let rooms = copy_accommodation.rooms.slice();
                    let copy_rooms = [];
                    rooms.map((room) => {
                        if (room.id === action.payload.data.id) {
                            let copy_room = Object.assign({}, room);
                            copy_room.booking_status = action.payload.data.booking_status;
                            room = copy_room;
                        }
                        copy_rooms.push(room);
                    });
                    copy_accommodation.rooms = copy_rooms;
                    accommodation = copy_accommodation;
                }
                copy_sort_cart.push(accommodation);
            });
            new_state.cart = copy_cart;
            new_state.sort_cart = copy_sort_cart;
            return new_state;
        }
        case "ACCOMMODATION_CURRENT_CART_ADD": {
            let current_cart = new_state.current_cart.slice();
            current_cart.push(action.payload.room);
            for (let i = 0; i < new_state.current_groups.length; i++) {
                let has_room = false;
                for (let j = 0; j < current_cart.length; j++) {
                    if (new_state.current_groups[i].id === current_cart[j].group[0]) {
                        has_room = true;
                    }
                }
                if (!has_room) {
                    new_state.index_current_group = i;
                    break;
                }
            }
            new_state.current_cart = current_cart;
            return new_state;
        }
        case "ACCOMMODATION_CURRENT_CART_REMOVE_AT_INDEX": {
            let current_cart = new_state.current_cart.slice();
            current_cart.splice(action.payload.index, 1);
            for (let i = 0; i < new_state.current_groups.length; i++) {
                let has_room = false;
                for (let j = 0; j < current_cart.length; j++) {
                    if (new_state.current_groups[i].id === current_cart[j].group[0]) {
                        has_room = true;
                    }
                }
                if (!has_room) {
                    new_state.index_current_group = i;
                    break;
                }
            }
            new_state.current_cart = current_cart;
            return new_state;
        }
        case 'CART_REFRESH_PRICE': {
            if (action.payload !== null) {
                new_state.refresh = action.payload.accommodations;
            }
            return new_state;
        }
        case 'ACCOMMODATION_LATE_ARRIVAL_DEPARTURE': {
            return {...new_state, late_arrival: action.payload.hasLateArrival, late_departure: action.payload.hasLateDeparture, choose_late_departure: false, choose_late_arrival: false};
        }
        case 'ACCOMMODATION_SET_ADDITIONAL_PRODUCTS': {
            return {...new_state, additional_products: action.payload};
        }

        /*
        case "ACCOMMODATION_TOGGLE_SHOW_SORTS": {
            new_state.show_sorts = action.payload.show_sorts;
            return new_state;
        }
        case "ACCOMMODATION_TOGGLE_ROOM_PICTURE_MODAL": {
            new_state.modal_pictures = action.payload.toggle_modal;
            new_state.room_pictures = action.payload.pictures;
            new_state.index_pictures = action.payload.index;
            return new_state;
        }
        case "ACCOMMODATION_TOGGLE_CALENDAR_MODAL": {
            new_state.modal_calendar = action.payload.modal_calendar;
            new_state.calendar_price = action.payload.calendar_price;
            return new_state;
        }
        case "ACCOMMODATION_TOGGLE_SORTS": {
            let sorts = Object.assign({}, new_state.sorts);
            Object.keys(sorts).forEach(key => {
                sorts[key] = (key === action.payload.key);
            });
            new_state.show_sorts = false;
            new_state.sorts = sorts;
            return new_state;
        }
        case "ACCOMMODATION_TOGGLE_ROOM_COMPOSITION": {
            new_state.show_room_composition = action.payload.show_room_composition;
            return new_state;
        }
        case "ACCOMMODATION_TOGGLE_FILTERS": {
            new_state.show_filters = action.payload.show_filters;
            return new_state;
        }
        case "ACCOMMODATION_TOGGLE_FILTER": {
            let filters = Object.assign({}, new_state.filters);
            filters[action.payload.filter] = !filters[action.payload.filter];
            new_state.filters = filters;
            return new_state;
        }
        case "ACCOMMODATION_EMPTY_FILTERS": {
            new_state.filters = {
                star_0: false,
                star_1: false,
                star_2: false,
                star_3: false,
                star_4: false,
                star_5: false,
                check_in: false,
                bar: false,
                restaurant: false,
                change: false,
                receptionist: false,
                elevator: false,
                parking: false,
                swimming_pool: false,
                room_service: false,
                television: false,
                safe: false,
                private_beach: false,
                wifi: false,
                air_conditioning: false,
                mini_bar: false,
            };
            new_state.full = false;
            new_state.recommended = false;
            return new_state;
        }
        case "ACCOMMODATION_TOGGLE_FAVORITE": {
            new_state.show_favorite = action.payload.show_favorite;
            return new_state;
        }
        case "ACCOMMODATION_SET_LIST": {
            new_state.list = action.payload.list;
            new_state.request_url = action.payload.request_url;
            if (action.payload.list.results.length === 0 && new_state.active_page > 1) {
                new_state.active_page = 1;
            }
            new_state.force_request = false;
            return new_state;
        }
        case "ACCOMMODATION_TOGGLE_FULL": {
            new_state.full = !new_state.full;
            return new_state;
        }
        case "ACCOMMODATION_TOGGLE_RECOMMENDED": {
            new_state.recommended = !new_state.recommended;
            return new_state;
        }
        case "ACCOMMODATION_SET_PRICES": {
            let list = Object.assign({}, new_state.list);
            let duration_days = moment(new_state.end_date).diff(moment(new_state.start_date), "d");
            let results = list.results.slice();
            let results_copy = [];
            for (let it = 0; it < results.length; it++) {
                let accommodation = Object.assign({}, results[it]);
                if (accommodation.price === undefined) {
                    accommodation.price = 0;
                }
                action.payload.list.map((dynamic) => {
                    if (accommodation.id === dynamic.hotel_id && dynamic.prices !== undefined && dynamic.prices !== null && dynamic.prices.length > 0) {
                        accommodation.catalog_price = (dynamic.details !== null ? dynamic.details.catalog_price : false);
                        if (accommodation.provider === undefined || accommodation.provider === null) {
                            accommodation.provider = [];
                        }
                        let already_added = false;
                        accommodation.provider.map((provider) => {
                            if (provider.data.id === action.payload.provider.id) {
                                already_added = true;
                            }
                        });
                        if (!already_added) {
                            if (dynamic.prices !== undefined && dynamic.prices !== null && dynamic.prices.length > 0) {
                                let dynamic_prices = 0;
                                for (let i = 0; i < dynamic.prices.length; i++) {
                                    if (dynamic.prices[i].owner === action.payload.owner) {
                                        let showing_price = dynamic.prices[i].selling_price;
                                        dynamic_prices = parseFloat(showing_price);
                                    }
                                }
                                accommodation.provider.push({
                                    data: action.payload.provider,
                                    price: (dynamic_prices / duration_days),
                                    preferred_lvl: action.payload.preferred_lvl,
                                    best: false,
                                });
                                if (accommodation.best_provider === undefined) {
                                    accommodation.best_provider = action.payload.provider.id;
                                    accommodation.provider.map((provider) => {
                                        provider.best = false;
                                    });
                                    accommodation.provider[accommodation.provider.length - 1].best = true;
                                }
                                const weight = parseInt(action.payload.weight);
                                if (accommodation.price === 0) {
                                    accommodation.price = (dynamic_prices / duration_days);
                                    accommodation.weight = weight;
                                    accommodation.preferred_lvl = action.payload.preferred_lvl;
                                }
                                if (accommodation.weight === weight) {
                                    if (accommodation.price > (dynamic_prices / duration_days)) {
                                        accommodation.best_provider = action.payload.provider.id;
                                        accommodation.provider.map((provider) => {
                                            provider.best = false;
                                        });
                                        accommodation.provider[accommodation.provider.length - 1].best = true;
                                        accommodation.price = (dynamic_prices / duration_days);
                                        accommodation.preferred_lvl = action.payload.preferred_lvl;
                                    }
                                } else if (accommodation.weight > weight) {
                                    accommodation.best_provider = action.payload.provider.id;
                                    accommodation.provider.map((provider) => {
                                        provider.best = false;
                                    });
                                    accommodation.provider[accommodation.provider.length - 1].best = true;
                                    accommodation.price = (dynamic_prices / duration_days);
                                    accommodation.preferred_lvl = action.payload.preferred_lvl;
                                }
                            }
                        }
                    }
                });
                results_copy.push(accommodation);
            }
            list.results = results_copy.slice();
            if (new_state.sorts.price_asc) {
                list.results.sort(function (a, b) {
                    return a.price - b.price;
                });
            } else if (new_state.sorts.price_dsc) {
                list.results.sort(function (a, b) {
                    return b.price - a.price;
                });
            }
            new_state.list = Object.assign({}, list);
            return new_state;
        }
        case "ACCOMMODATION_SET_DETAILED_PRICES": {
            let current_details = Object.assign({}, new_state.current_details);
            if (current_details.id !== undefined) {
                if (current_details.price === undefined) {
                    current_details.price = 0;
                }
                if (current_details.provider === undefined || current_details.provider === null) {
                    current_details.provider = [];
                }
                let already_added = false;
                current_details.provider.map((provider) => {
                    if (provider.data.id === action.payload.provider.id) {
                        already_added = true;
                    }
                });
                if (!already_added) {
                    let duration_days = moment(action.payload.end_date).diff(moment(action.payload.start_date), "d");
                    action.payload.list.map((dynamic) => {
                        if (dynamic.prices !== undefined && dynamic.prices !== null && dynamic.prices.length > 0) {
                            let dynamic_prices = 0;
                            for (let i = 0; i < dynamic.prices.length; i++) {
                                if (dynamic.prices[i].owner === action.payload.owner) {
                                    let showing_price = dynamic.prices[i].selling_price;
                                    dynamic_prices = parseFloat(showing_price);
                                }
                            }
                            current_details.provider.push({
                                data: action.payload.provider,
                                price: (dynamic_prices / duration_days),
                                best: false,
                                preferred_lvl: action.payload.preferred_lvl
                            });
                            if (current_details.best_provider === undefined) {
                                current_details.best_provider = action.payload.provider.id;
                                current_details.provider.map((provider) => {
                                    provider.best = false;
                                });
                                current_details.provider[current_details.provider.length - 1].best = true;
                            }
                            const weight = parseInt(action.payload.weight);
                            if (current_details.price === 0) {
                                current_details.price = (dynamic_prices / duration_days);
                                current_details.weight = weight;
                            }
                            if (current_details.weight === weight) {
                                if (current_details.price > (dynamic_prices / duration_days)) {
                                    current_details.best_provider = action.payload.provider.id;
                                    current_details.provider.map((provider) => {
                                        provider.best = false;
                                    });
                                    current_details.provider[current_details.provider.length - 1].best = true;
                                    current_details.price = (dynamic_prices / duration_days);
                                }
                            } else if (current_details.weight > weight) {
                                current_details.best_provider = action.payload.provider.id;
                                current_details.provider.map((provider) => {
                                    provider.best = false;
                                });
                                current_details.provider[current_details.provider.length - 1].best = true;
                                current_details.price = (dynamic_prices / duration_days);
                            }
                        }
                    });
                }
                new_state.start_date = action.payload.start_date;
                new_state.end_date = action.payload.end_date;
                new_state.current_details = current_details;
                return new_state;
            }
        }
        case "ACCOMMODATION_ADD_CATALOG_PRICE": {
            let catalog_price_cpy = new_state.catalog_price.slice();
            catalog_price_cpy.push(action.payload.catalog_price);
            new_state.catalog_price = catalog_price_cpy;
            return new_state;
        }
        case "ACCOMMODATION_TOGGLE_TRIPADVISOR": {
            new_state.show_tripadvisor = action.payload.show_tripadvisor;
            return new_state;
        }
        case "ACCOMMODATION_SET_TRIPADVISOR": {
            new_state.tripadvisor = action.payload.tripadvisor;
            return new_state;
        }
        case "ACCOMMODATION_CHANGE_GRID_TYPE": {
            new_state.grid_type = action.payload.grid_type;
            return new_state;
        }
        case "ACCOMMODATION_ADD_SEEN": {
            new_state.seen = [].concat(new_state.seen, action.payload.accommodation);
            return new_state;
        }
        case "ACCOMMODATION_ADD_FAVORITE": {
            new_state.favorite = [].concat(new_state.favorite, action.payload.accommodation);
            return new_state;
        }
        case "ACCOMMODATION_REMOVE_FAVORITE": {
            let favorite = new_state.favorite.slice();
            favorite.splice(action.payload.index, 1);
            new_state.favorite = favorite.slice();
            return new_state;
        }
        case "ACCOMMODATION_SET_CURRENT_DETAILS": {
            new_state.current_details = action.payload.details;
            return new_state;
        }
        case "ACCOMMODATION_SET_CURRENT_ROOMS": {
            new_state.current_rooms = action.payload.rooms;
            return new_state;
        }
        case "ACCOMMODATION_SET_CURRENT_CART": {
            new_state.current_cart = action.payload.cart;
            return new_state;
        }
        case "ACCOMMODATION_SET_ROOMS": {
            new_state.current_rooms = action.payload.rooms;
            let current_rooms = action.payload.rooms.slice();
            if (current_rooms !== null) {
                for (let i = 0; i < current_rooms.length; i++) {
                    if (current_rooms[i] !== null && current_rooms[i].rooms.length > 0) {
                        let new_rooms = [];
                        while (current_rooms[i].rooms.length > 0) {
                            new_rooms.push(sort(current_rooms[i], action.payload.owner));
                        }
                        current_rooms[i].rooms = new_rooms;
                    }
                }
            }
            new_state.current_cart = [];
            new_state.current_provider = action.payload.provider;
            return new_state;
        }
        case "ACCOMMODATION_SET_GROUPS": {
            new_state.current_groups = action.payload.groups;
            SetCookie("current_groups", JSON.stringify(new_state.current_groups), 365, `/${window.url_name}`, (window.location.host === "localhost:3000" ? null : window.location.host));
            new_state.index_current_group = 0;
            new_state.force_request = true;
            new_state.show_room_composition = false;
            return new_state;
        }
        case "ACCOMMODATION_SET_GROUPS_AND_DATES": {
            new_state.current_groups = action.payload.groups;
            SetCookie("current_groups", JSON.stringify(new_state.current_groups), 365, `/${window.url_name}`, (window.location.host === "localhost:3000" ? null : window.location.host));
            new_state.index_current_group = 0;
            new_state.force_request = true;
            new_state.show_room_composition = false;
            new_state.start_date = action.payload.start_date;
            new_state.end_date = action.payload.end_date;
            return new_state;
        }
        case "ACCOMMODATION_SET_SEARCH": {
            new_state.search = action.payload.search;
            return new_state;
        }
        case "ACCOMMODATION_CHANGE_ACTIVE_PAGE": {
            new_state.active_page = action.payload.active_page;
            return new_state;
        }
        case "ACCOMMODATION_SEE_NEW_CARD": {
            let add = true;
            for (let i = 0; i < new_state.seen.length; i++) {
                if (new_state.seen[i] === action.payload.id) {
                    add = false;
                }
            }
            if (add) {
                new_state.seen.push(parseInt(action.payload.id));
            }
            new_state.current_card_id = action.payload.id;
            new_state.current_cart = [];
            new_state.index_current_group = 0;
            new_state.current_provider = null;
            new_state.current_rooms = null;
            return new_state;
        }
        case "ACCOMMODATION_SET_OVER": {
            new_state.over = action.payload.over;
            return new_state;
        }
        case "ACCOMMODATION_SET_START_DATE": {
            new_state.start_date = action.payload.start_date;
            new_state.force_request = true;
            return new_state;
        }
        case "ACCOMMODATION_SET_END_DATE": {
            new_state.end_date = action.payload.end_date;
            new_state.force_request = true;
            return new_state;
        }
        case "ACCOMMODATION_SET_DATES": {
            new_state.start_date = action.payload.start_date;
            new_state.end_date = action.payload.end_date;
            new_state.force_request = action.payload.force_request;
            new_state.show_room_composition = false;
            return new_state;
        }
         */
    }
    return new_state;
}
