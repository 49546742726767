import { sortItinerary } from "../Components/Itinerary/utils/sortItinerary";

const initial_state = {
    destination_list: [],
    destination_prev: [],
    destination_list_show: true,
    //START Google destination
    departure_destination: {
        city: null,
        country: null,
        place_id: null,
        lat: null,
        lng: null,
        start_date: null,
        end_date: null,
        retrieve: false
    },
    return_destination: {
        city: null,
        country: null,
        place_id: null,
        lat: null,
        lng: null,
        start_date: null,
        end_date: null,
        retrieve: false
    },
    //END
    edit_destination_id: null,
    edit_destination_list: [],
    itinerary_list: [],
    itinerary_loaded: false,
    itinerary_return_same: true,
    edit_input_triggered: false,
    modal: false,
    toggle_panel: false,
    transport_panel: null,
    step_panel: 1,
    transport_mode: null,
    choosed_transport: null,
    FillFlag: null,
    index_transport: null,
    airport_error: false,
    airport_error_message: null,
    modal_info: false,
    destination_info: null,
    guide: null,
    zoom_on_destination_list: false,
    hidden_panel: false,
    old_panel_state: false,
    selectTransportflag: null,
    show_arrow: true,
    index: null,
    destination_modal_list: [],
    filter_destination: "current_version__localization__name",
    journey_type: null,
    reload_cart_modal: false,
    step_reload_cart: 2,
    cart_update: false,
    update_cart_flag: false,
    outbound: "",
    system_change: false,
    geo_json: null
};

export default function reducer(state = initial_state, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
        case "persist/REHYDRATE": {
            if (action.payload !== undefined && action.payload.itinerary !== undefined) {
                Object.keys(action.payload.itinerary).forEach(key => {
                    if (new_state[key] !== undefined && key !== "itinerary_loaded") {
                        new_state[key] = action.payload.itinerary[key];
                    }
                });
            }
            return new_state;
        }
        case "FLUSH_REMOVE_STATE": {
            new_state = initial_state;
            return new_state;
        }
        case "SMALL_FLUSH": {
            new_state = initial_state;
            return new_state;
        }
        case "MATERIAL_APPLY_MOVED": {
            new_state.zoom_on_destination_list = false;
            return new_state;
        }
        case "MATERIAL_UPDATE_MAP": {
            new_state.zoom_on_destination_list = false;
            return new_state;
        }


        //---- Set the destination list present on the left of the itinerary, this is used when the header destination change or when the trip is loaded from the database ----//
        case "ITINERARY_SET_DESTINATION_LIST": {
            new_state.destination_list = action.payload.destination_list;
            new_state.zoom_on_destination_list = action.payload.zoom;
            new_state.show_arrow = true;
            new_state.system_change = true;
            return new_state;
        }
        case "ITINERARY_SET_DESTINATION_LIST_AND_ARROW": {
            new_state.destination_list = action.payload.destination_list;
            new_state.zoom_on_destination_list = true;
            new_state.show_arrow = action.payload.show_arrow;
            new_state.system_change = true;
            return new_state;
        }
        case "ITINERARY_SET_ARROW": {
            new_state.show_arrow = action.payload.show_arrow;
            return new_state;
        }
        case "ITINERARY_SET_ZOOM_ON_DESTINATION_LIST": {
            new_state.destination_list = action.payload.destination_list;
            new_state.show_arrow = true;
            new_state.system_change = true;
            return new_state;
        }
        case "ITINERARY_EDIT_DESTINATION": {
            new_state.edit_destination_id = action.payload.id;
            return new_state;
        }
        case "ITINERARY_EDIT_DESTINATION_SET_LIST": {
            new_state.edit_destination_list = action.payload.list;
            return new_state;
        }
        //---- Allow to hide or show the destination list present on the left of the itinerary, useful to have a better vue of the itinerary
        case "ITINERARY_TOGGLE_DESTINATION_LIST": {
            new_state.destination_list_show = action.payload.destination_list_show;
            return new_state;
        }
        case "ITINERARY_ZOOM_ON_DESTINATION_LIST": {
            new_state.zoom_on_destination_list = false;
            return new_state;
        }
        //----- Toggle modal ----//
        case "ITINERARY_DESTINATION_TOGGLE_MODAL" : {
            new_state.modal = action.payload.modal;
            new_state.index = action.payload.index;
            return new_state;
        }
        case "ITINERARY_TOGGLE_PANEL" : {
            new_state.hidden_panel = action.payload.hidden_panel;
            return new_state;
        }
        case "ITINERARY_TOGGLE_PANEL_STATE" : {
            new_state.hidden_panel = action.payload.old_panel_state;
            return new_state;
        }
        case "ITINERARY_TOGGLE_PANEL_AND_STATE" : {
            new_state.hidden_panel = action.payload.hidden_panel;
            new_state.old_panel_state = action.payload.old_panel_state;
            return new_state;
        }
        case "ITINERARY_DESTINATION_CHANGE": {
            new_state.destination = action.payload.destination;
            return new_state;
        }
        case "ITINERARY_DESTINATION_LIST_CHANGE": {
            new_state.destination_list = action.payload.destination_list;
            return new_state;
        }
        //---- Used when the trip is loaded from the database ----//
        case "ITINERARY_SET_ITINERARY_LIST": {
            let new_iti = [...action.payload.itinerary_list].sort(sortItinerary);
            for (let i = 0; i < new_iti.length; i++) {
                if (new_iti[i].step_type === "START") {
                    new_state.departure_destination.start_date = new_iti[i].start_date;
                    new_state.departure_destination.end_date = new_iti[i].end_date;
                } else if (new_iti[i].step_type === "END") {
                    new_state.return_destination.start_date = new_iti[i].start_date;
                    new_state.return_destination.end_date = new_iti[i].end_date;
                }
            }
            new_state.modal = false;
            new_state.itinerary_list = new_iti;
            new_state.edit_destination_id = null;
            new_state.edit_destination_list = [];
            return new_state;
        }
        //---- Allow to add new transport in the itinerary ----//
        case "ITINERARY_ADD_ITINERARY": {
            new_state.itinerary_list = [].concat(new_state.itinerary_list, action.payload.destination);
            return new_state;
        }
        //---- This is a quick approach to update a specific transport (useful while waiting for R2R to respond) ----//
        case "ITINERARY_CHANGE_ITINERARY_AT_INDEX": {
            let itinerary_list = new_state.itinerary_list.slice();
            itinerary_list[action.payload.index] = action.payload.destination;
            new_state.itinerary_list = itinerary_list;
            return new_state;
        }
        //---- True when itinerary component is mounted, this notify the map to init the google input ----//
        case "ITINERARY_LOADED": {
            new_state.itinerary_loaded = action.payload.itinerary_loaded;
            return new_state;
        }
        //---- Set the departure and the destination of return for the trip ----//
        case "ITINERARY_SET_DEPARTURE_RETURN": {
            new_state.departure_destination = action.payload.departure_destination;
            new_state.return_destination = action.payload.return_destination;
            return new_state;
        }
        case "ITINERARY_SET_DEPARTURE": {
            new_state.departure_destination = action.payload.departure_destination;
            return new_state;
        }
        case "ITINERARY_SET_RETURN": {
            new_state.return_destination = action.payload.return_destination;
            return new_state;
        }
        //---- Toggle return city same as departure ----//
        case "ITINERARY_TOGGLE_RETURN_CITY_SAME": {
            new_state.itinerary_return_same = action.payload.itinerary_return_same;
            return new_state;
        }
        //---- Allow to init google input if user want's to change departure or return city ----//
        case "ITINERARY_TOGGLE_EDIT_INPUT": {
            new_state.edit_input_triggered = action.payload.edit_input_triggered;
            return new_state;
        }
        case "ITINERARY_TOGGLE_TRANSPORT_PANEL": {
            new_state.toggle_panel = action.payload.toggle_panel;
            new_state.transport_panel = action.payload.transport_panel;
            new_state.step_panel = action.payload.step_panel;
            return new_state;
        }
        case "ITINERARY_TOGGLE_MORE_INFO_MODAL": {
            new_state.modal_info = action.payload.modal_info;
            new_state.destination_info = action.payload.destination_info;
            new_state.guide = action.payload.guide;
            return new_state;
        }
        case "ITINERARY_CHANGE_STEP_PANEL": {
            new_state.step_panel = action.payload.step_panel;
            new_state.transport_mode = action.payload.transport_mode;
            return new_state;
        }
        case "ITINERARY_SET_CHOOSED_TRANSPORT": {
            new_state.choosed_transport = action.payload.choosed_transport;
            new_state.index_transport = action.payload.index_transport;
            return new_state;
        }
        case "ITINERARY_SET_AIRPORT_ERROR": {
            new_state.airport_error = action.payload.airport_error;
            new_state.airport_error_message = action.payload.airport_error_message;
            return new_state;
        }
        case "ITINERARY_SELECT_TRANSPORT_FLAG": {
            new_state.selectTransportflag = action.payload.status;
            return new_state;
        }
        case "ITINERARY_DESTINATION_MODAL_LIST_CHANGE": {
            new_state.destination_modal_list = action.payload.destination_modal_list;
            return new_state;
        }
        case "ITINERARY_DESTINATION_FILTER_CHANGE": {
            new_state.filter_destination = action.payload.filter_destination;
            return new_state;
        }
        case "ITINERARY_SET_JOURNEY_TYPE": {
            new_state.journey_type = action.payload.journey_type;
            return new_state;
        }
        case "ITINERARY_SET_FILL": {
            new_state.FillFlag = action.payload.flag;
            return new_state;
        }
        case "ITINERARY_TOGGLE_RELOAD_CART_MODAL_AND_SET_STEP": {
            new_state.reload_cart_modal = action.payload.reload_cart_modal;
            new_state.step_reload_cart = action.payload.step_reload_cart;
            return new_state;
        }
        case "ITINERARY_RELOAD_CART_MODAL_SET_STEP": {
            new_state.step_reload_cart = action.payload.step_reload_cart;
            return new_state;
        }
        case "ITINERARY_TOGGLE_CART_UPDATE": {
            new_state.cart_update = action.payload.cart_update;
            return new_state;
        }
        case "ITINERARY_SET_UPDATE_CART_FLAG": {
            new_state.update_cart_flag = action.payload.flag;
            return new_state;
        }
        case "OPTIMIZATION_DESTINATION_CHANGE": {
            new_state.destination_list = action.payload.itinerary_destination_list;
            new_state.zoom_on_destination_list = true;
            new_state.show_arrow = true;
            return new_state;
        }
        case "ITINERARY_SET_OUTBOUND": {
            new_state.outbound = action.payload.outbound;
            return new_state;
        }
        case "ITINERARY_SET_GEOJSON": {
            new_state.geo_json = action.payload;
            return new_state;
        }
    }
    return new_state;
}
